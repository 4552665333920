import {Navigate, useRoutes} from 'react-router-dom';
import {lazy} from "react";

const WelcomePage = lazy(() => import('../../pages/welcome'));
const FormPage = lazy(() => import('../../pages/form'));
const SurveyPage = lazy(() => import('../../pages/survey'));
const CoroPage = lazy(() => import('../../pages/coro'));
const Page404 = lazy(() => import('../../pages/404'));
const ExpirationSession = lazy(() => import('../../pages/expiration-session'));


export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <WelcomePage/>,
            index: true,
        },
        {
            path: '/form/:publicKey/:formId',
            element: <FormPage/>,
        },
        {
            path: '/survey/:publicKey/:surveyId',
            element: <SurveyPage/>,
        },
        {
            path: '/sessions/:surveyId',
            element: <SurveyPage/>,
        },
        {
            path: '/expiration-session',
            element: <ExpirationSession/>,
        },
        {
            path: '/research',
            element: <CoroPage/>
        },
        {path: '404', element: <Page404/>},
        // No match 404
        {path: '*', element: <Navigate to="/404" replace/>},
    ]);
}
