import React from 'react';
import Router from "./routes/sections";
import './App.css';

/* i18n translation variables */
import './assets/i18n/i18n';

function App() {
    return (
        <div className="App">
            <Router/>
        </div>
    );
}

export default App;
